.dfp-storage-external-icon {
  color: cornflowerblue;
}

.file-wrapper .dfp-storage-external-icon {
  position: absolute;
  left: 5px;
  bottom: 2px;
  opacity: 0.7;
}

.title-area .dfp-storage-external-icon {
  margin-left: 8px;
  font-size: 1.1rem;
}

[id="page-DFP External Storage"] button.btn-new[data-doctype=File] {
  display: none;
}